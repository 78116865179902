<template>
  <div>
    <div class="no-gutters board-auth-box">
      <div class style="max-width: 450px; width: 100%; margin: auto;">
        <el-form :rules="rules" ref="registerForm" :model="registerForm" class="registerContainer" label-width="20%">
		<h3 class="loginTitle">账号注册</h3>
		  <el-form-item prop="name" label="昵称">
		    <el-input type="text" v-model="registerForm.name" placeholder="请输入昵称"></el-input>
		  </el-form-item>          
          <el-form-item prop="phone" label="手机号">
            <el-input type="text" v-model="registerForm.phone" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码">
            <el-input type="password" auto-complate="false" v-model="registerForm.password"
                      placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-button type="primary" @click="submitRegister">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router";
import {register} from "../../api/auth";
import {Message} from "element-ui";

export default {
  name: "Register",
  data() {
    return {
      registerForm: {
        password: '',
        phone: '',
        name: ''
      },
      rules: {
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
        phone: [{required: true, message: '请输入手机号', trigger: 'blur'}],
        name: [{required: true, message: '请输入昵称', trigger: 'blur'}],
      }
    }
  },
  methods: {
    submitRegister() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
		  this.registerForm.account=this.registerForm.phone;
          register(this.registerForm, function (res) {
            if (res) {
              Message.success('注册成功！')
              //跳转到登陆页
              router.replace('/');
            }
          })
        } else {
          this.$message.error('请输入所有字段');
          return false;
        }
      });
    },
    cancel() {
      router.replace("/");
    }
  }
}
</script>

<style>
.registerContainer {
  border-radius: 15px;
  padding: 15px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.loginTitle {
  margin: 0px auto 25px auto;
  text-align: center;
}
</style>