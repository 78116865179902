import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/pages/Login'
import Register from "../views/pages/Register";
import Home from "../views/Home";
import Mobile from "../views/Mobile";
import Files from "../views/modules/filePages/files";
import Video from "../views/modules/video/video";
import OfflineDownload from "../views/modules/filePages/offlineDownload";
import ShareFiles from "../views/modules/sharePages/shareFiles";
import SharedFiles from "../views/modules/sharePages/sharedFiles";
import ChangePassword from "../views/pages/ChangePassword";
import personalCenter from "../views/pages/personalCenter";
import CollectFiles from "../views/modules/collectFilesPages/collectFiles";
import NodePages from "../views/modules/notePages/nodePages";
import RecyclePage from "../views/modules/recycle/recyclePage";
import VersionPage from "../views/modules/versionPages/versionPage";

const routes = [
	{
		path: '/register',
		name: 'Register',
		component: Register,
		hidden: true
	},

	{
		path: '/',
		name: 'Login',
		component: Login,
		hidden: true
	},
	{
		path: '/changePassword',
		name: 'ChangePassword',
		component: ChangePassword,
		hidden: true
	}, {
		path: '/personalCenter',
		name: 'personalCenter',
		component: personalCenter,
		hidden: true
	}, {
		path: '/mobile',
		name: 'mobile',
		component: Mobile,
		children:[{
				path: '/mobile-files',
				name: '全部文件',
				iconCls: 'el-icon-files',
				component: Files
			},
			{
				path: '/mobile-video',
				name: '影视仓库',
				iconCls: 'el-icon-video-camera',
				component: Video
			},
			{
				path: '/mobile-shareFiles',
				name: '我的分享',
				iconCls: 'el-icon-connection',
				component: ShareFiles
			},
			// {
			// 	path: '/sharedFiles',
			// 	name: '共享文件',
			// 	iconCls: 'el-icon-connection',
			// 	component: SharedFiles
			// },
			// {
			// 	path: '/collectFiles',
			// 	name: '收集文件',
			// 	iconCls: 'el-icon-folder-add',
			// 	component: CollectFiles
			// },
			// {
			// 	path: '/test',
			// 	name: '笔记管理',
			// 	iconCls: 'el-icon-tickets',
			// 	component: NodePages
			// },
			// {
			// 	path: '/offlineDownload',
			// 	name: '离线下载',
			// 	iconCls: 'el-icon-folder-add',
			// 	component: OfflineDownload
			// },
			{
				path: '/mobile-recycle',
				name: '回收站',
				iconCls: 'el-icon-delete',
				component: RecyclePage
			},
			{
				path: '/mobile-version',
				name: '版本记录',
				iconCls: 'el-icon-warning-outline',
				component: VersionPage
			}
		]
	},
	{
		path: '/home',
		name: '我的网盘',
		component: Home,
		redirect: '/files', //默认选中子路由全部文件为主页
		children: [{
				path: '/files',
				name: '全部文件',
				iconCls: 'el-icon-files',
				component: Files
			},
			{
				path: '/video',
				name: '影视仓库',
				iconCls: 'el-icon-video-camera',
				component: Video
			},
			{
				path: '/shareFiles',
				name: '我的分享',
				iconCls: 'el-icon-connection',
				component: ShareFiles
			},
			// {
			// 	path: '/sharedFiles',
			// 	name: '共享文件',
			// 	iconCls: 'el-icon-connection',
			// 	component: SharedFiles
			// },
			// {
			// 	path: '/collectFiles',
			// 	name: '收集文件',
			// 	iconCls: 'el-icon-folder-add',
			// 	component: CollectFiles
			// },
			// {
			// 	path: '/test',
			// 	name: '笔记管理',
			// 	iconCls: 'el-icon-tickets',
			// 	component: NodePages
			// },
			// {
			// 	path: '/offlineDownload',
			// 	name: '离线下载',
			// 	iconCls: 'el-icon-folder-add',
			// 	component: OfflineDownload
			// },
			{
				path: '/recycle',
				name: '回收站',
				iconCls: 'el-icon-delete',
				component: RecyclePage
			},
			{
				path: '/version',
				name: '版本记录',
				iconCls: 'el-icon-warning-outline',
				component: VersionPage
			}]
	}
]

import VueDeviceDetector from "vue-device-detector"; // 引入 VueDeviceDetector

const router = new VueRouter({
	routes
})

Vue.use(VueRouter)
Vue.use(VueDeviceDetector);

// router.beforeEach((to, from, next) => {
//   if (Vue.prototype.$device.mobile && to.path === '/home') {
//     // 如果是移动端设备，且不在移动端页面路径，则跳转到移动端页面
//     next('/mobile');
//   } else if (!Vue.prototype.$device.mobile && to.path === '/mobile') {
//     // 如果不是移动端设备，但访问的是移动端页面，则跳转到主页
//     next('/home');
//   } else {
//     next();
//   }
// });
export default router